import axios from "axios";
import i18next from "i18next";
import Emitter from "./Emitter";

let HOST_API =
  //  "https://uatapi.luxecrews.com/luxecrews/"  //UAT
  //  'https://api1.luxecrews.com/luxecrews/' //PROD
  "https://dev.luxecrews.com/luxecrews/" // DEV
// 'https://bb62-137-59-64-76.in.ngrok.io/' //local
// 'https://api.luxecrews.com/' //LIVE no need to add '/luxecrews' in LIVE server


const clientId = "luxecrews-admin-console";
const OauthPwd =
  "xX9{hP4>tG5@vS3#pG3!zL4$dG2&dT4,gH4{aH6:tM3^lM6>oP8)kW8!eG8+pZ4~";

let myInterceptor;
let auth;
let refreshToken;

// export function setAuthToken(token) {
//   auth = token;
// }
export function setAuthToken(token, refreshtoken) {
  auth = token ? "Bearer " + token : "";
  // auth = token ? "Bearer " : "";
  refreshToken = refreshtoken ? refreshtoken : "";
}

if (!myInterceptor) {
  //let that = this;
  myInterceptor = axios.interceptors.request.use(
    function (config) {
      config.timeout = 0.5 * 60 * 1000; // 0.5 min

      if (auth) {
        config.headers["Authorization"] = auth;
      }
      config.headers["Content-Type"] = "application/json";
      config.headers["Accept-Language"] = "en";
      config.headers["Device-Id"] = "awd";
      config.headers["Device-Type"] = "windows";
      config.headers["App-Version"] = "0.0.1";
      config.headers["Device-Name"] = "moto";
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      console.log("interceptors.response-------------------------------------->", response)
      // if (response && response.data && response.data.code === 200)
      if (response && response.data && response.status === 200) {
        // return response.data;
        return Promise.resolve(response.data);
        //change to promise
      }
      return Promise.reject(response);
    },
    async function (error) {
      console.log("error--------------------------------------------------------->", error);
      const originalRequest = error.config;
      if (
        error &&
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + access_token;
        return axios(originalRequest);
      } else if (
        error.message &&
        error.message.toLowerCase().indexOf("timeout") > -1
      ) {
        error.data = {};
        error.data.message = i18next.t("ERROR_SERVER_MESSAGE");
      } else if (
        error.message &&
        error.message.toLowerCase().indexOf("network") > -1
      ) {
        error.data = {};
        error.data.message = i18next.t("ERROR_NETWORK_MESSAGE");
      }
      return Promise.reject(error);
    }
  );
}

// For Reference

export function adminLogin(body) {
  auth = "";
  body.oauth2ClientId = clientId;
  return axios.post(`${HOST_API}api/v1/auth/email/login`, body);
}
// export function refreshAccessToken(body) {
//   auth = "";
//   return axios.post(`${HOST_API}oauth/token`, body);
// }
export function refreshAccessToken(body) {
  const basicAuthData = "Basic " + btoa(`${clientId}:${OauthPwd}`);
  return new Promise((resolve, reject) => {
    //Calling oauth/token api with separate axios instance and config
    let instance = axios.create();
    instance
      .request({
        baseURL: HOST_API,
        url: "oauth/token",
        method: "post",
        headers: {
          Authorization: basicAuthData,
          "Content-Type": "application/x-www-form-urlencoded",
          "Accept-Language": "en",
          "Device-Id": "awd",
          "Device-Type": "windows",
          "App-Version": "0.0.1",
          "Device-Name": "moto",
        },
        data: new URLSearchParams({
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        }),
      })
      .then(function (response) {
        if (response && response.data && response.status === 200) {
          setAuthToken(response.data.access_token, response.data.refresh_token);
          Emitter.emit("UPDATE_TOKEN", response.data);
          resolve(response.data.access_token);
          //change to promise
        }
        // Emitter.emit("LOGOUT", {});
        reject(response);
      })
      .catch(function (error) {
        Emitter.emit("LOGOUT", {});
        reject(error);
      });
  });
}
export function forgotPassword(body) {
  return axios.post(`${HOST_API}api/v1/otp/send/mail`, body);
}

export function verifyOTP(body) {
  body.oauth2ClientId = clientId;
  return axios.post(`${HOST_API}api/v1/otp/forgot/password/verify`, body);
}
export function resetPassword(body) {
  return axios.put(`${HOST_API}api/v1/auth/reset/password`, body);
}

export function getUserList(param) {
  return axios.get(
    `${HOST_API}api/v1/admin/user/list?page=${param.page}&size=${param.size
    }&filter=${param.filter}&sort=${param.sort}&order=${param.order
    }&searchText=${encodeURI(param.searchText)}&roleFilter=${param.role}`
  );
}

export function getUserDetails(userId) {
  return axios.get(
    `${HOST_API}api/v1/admin/user/get/userDetail?userId=${userId}`
  );
}

export function blockUsers(body) {
  return axios.post(`${HOST_API}api/v1/admin/user/block`, body);
}

export function unBlockUsers(body) {
  return axios.post(`${HOST_API}api/v1/admin/user/unBlock`, body);
}

export function deleteUsers(body) {
  return axios.post(`${HOST_API}api/v1/admin/user/delete`, body);
}

export function approveUser(userId) {
  return axios.post(`${HOST_API}api/v1/admin/user/approve?userId=${userId}`);
}

export function rejectUser(userId) {
  return axios.post(`${HOST_API}api/v1/admin/user/reject?userId=${userId}`);
}

export function getAllEntityUpdateInfo() {
  return axios.get(`${HOST_API}api/v1/master/data/last/update/date`)
}

export function downloadFile(entityName) {
  return axios.get(`${HOST_API}api/v1/master/data/download?entityName=${entityName}`)
}

export function updateFile(body, entityName) {
  return axios.post(`${HOST_API}api/v1/master/data/upload?entityName=${entityName}`, body)
}

export function getTripList(param) {
  return axios.get(
    `${HOST_API}api/v1/admin/trip/list?page=${param.page}&size=${param.size
    }&sort=${param.sort}&order=${param.order
    }&searchText=${encodeURI(param.searchText)}`
  );
}

export function getTripDetails(tripId) {
  return axios.get(
    `${HOST_API}api/v1/trip/details?id=${tripId}`
  );
}

export function deleteTrip(tripId) {
  return axios.delete(`${HOST_API}api/v1/trip/delete?tripId=${tripId}`);
}

export function getJobListAPI(body) {
  return axios.get(`${HOST_API}api/v1/admin/job/list?crewPosition=${body.crewPosition}&employmentStatus=${body.employmentStatus}&page=${body.page}&size=${body.size}&searchText=${body.searchText}`)
}

export function getJobDetailsAPI(jobId) {
  return axios.get(`${HOST_API}api/v1/job/details?jobId=${jobId}`)
}

export function deleteJobAPI(body) {
  return axios.delete(`${HOST_API}api/v1/flight/department/delete/job?jobId=${body}`)
}


export function getSubscriptionAPI() {
  // console.log("heloperr")
  return axios.post(`${HOST_API}api/v1/admin/subscription/getall`)
}


export function createSubscriptionAPI(body) {
  // console.log("helper body for create subscription======>",body)
  return axios.post(`${HOST_API}api/v1/admin/subscription/create`, body);

}
export function deleteSubscriptionAPI(body) {
  console.log("delete subscription helper body===>", body)
  return axios.post(`${HOST_API}api/v1/admin/subscription/delete`, body)
}

export function activateDeactivateSubscriptionAPI(body) {
  console.log("activate deactivate helper body===>", body)
  return axios.post(`${HOST_API}api/v1/admin/subscription/activatedeactivate`, body)
}

export function editSubscriptionAPI(body) {
  console.log("edit subscription helper boby", body)
  return axios.post(`${HOST_API}api/v1/admin/subscription/update`, body)
}


